<template>
  <div>
    <div v-if="!clubConfig.name">
      <a
        class="btn btn-primary font-weight-bold d-none d-sm-inline"
        :class="klass"
        @click="reserveGeneric('desktop')"
        :style="genericButtonStyle"
      >
        {{ text }}
      </a>

      <a
        class="btn btn-primary font-weight-bold d-inline-xs d-sm-none card-animation-on-hover"
        :class="klass"
        :style="genericButtonStyle"
        @click="reserveGeneric('mobile')"
      >
        {{ text }}
      </a>
    </div>
    <div v-if="clubConfig.name">
      <a
        href="#"
        :class="klass"
        :style="clubButtonStyle"
        class="btn btn-primary font-weight-bold d-none d-sm-inline card-animation-on-hover"
        @click="reserveClub('desktop', clubConfig)"
      >
        {{ text }}
      </a>

      <a
        href="#"
        :class="klass"
        :style="clubButtonStyle"
        class="btn btn-primary font-weight-bold d-inline-xs d-sm-none card-animation-on-hover"
        @click="reserveClub('mobile', clubConfig)"
      >
        {{ text }}
      </a>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2';

const BASE_MODAL = {
  backdrop: true,
  html: '',
  showCloseButton: true,
  title: '',
  showDenyButton: true,
  showConfirmButton: true,
  confirmButtonText: 'WhatsApp',
  confirmButtonClass: 'btn btn-block',
  denyButtonClass: 'btn btn-block',
  denyButtonText: 'SMS',
  imageWidth: 250,
  width: 350,
  imageHeight: 250,
  confirmButtonColor: '#2e3241',
  denyButtonColor: '#2e3241',
  imageAlt: 'Custom image',
  background: '#616466',
};

export default {
  name: 'RsvpViaDialog',
  props: {
    klass: {
      type: String,
      default: 'btn-sm',
    },
    clubConfig: {
      type: Object,
      default() {
        return {};
      },
    },
    text: {
      type: String,
      default: ' 📲 INFO & RSVP',
    },
    legend: {
      type: String,
      default: 'Information & Reservations',
    },
    footer: {
      type: String,
      default: 'Secure your spot now!',
    },
    phoneNumber: {
      type: String,
      default: '524421867547',
    },
    message: {
      type: String,
      default: null,
    },
    mailAddress: {
      type: String,
      default: null,
    },
    waQR: {
      type: String,
      default: 'https://imgix.cosmicjs.com/c3b60240-f712-11ec-bf2b-e93971fa55b1-image.png',
    },
    smsQR: {
      type: String,
      default: 'https://imgix.cosmicjs.com/140a0b30-f22f-11ec-a2eb-c1653f3f9199-telegram-cloud-photo-size-1-5014946017338042974-x.jpg',
    },
  },
  computed: {
    genericButtonStyle() {
      return {
        color: 'black !important',
        backgroundColor: 'aquamarine !important',
        border: '1px solid silver',
      };
    },
    clubButtonStyle() {
      return {
        backgroundColor: this.clubConfig.color,
      };
    },
  },
  methods: {
    reserveGeneric(device) {
      if (device === 'desktop') {
        this.rsvpDialog('generic', 'desktop');
      } else {
        this.rsvpDialog('generic', 'mobile');
      }
    },
    reserveClub(device, clubConfig) {
      if (device === 'desktop') {
        this.rsvpDialog('club', 'desktop', clubConfig);
      } else {
        this.rsvpDialog('club', 'mobile', clubConfig);
      }
    },
    rsvpDialog(type, device, clubConfig = {name: 'clubs'}) {
      let options = Object.assign({}, BASE_MODAL, {
        title: '',
        html: this.getDialogHtml(type, clubConfig),
        footer: this.mailFooter(clubConfig),
        background: clubConfig.primaryColor || BASE_MODAL.background,
        confirmButtonColor: clubConfig.secondaryColor || BASE_MODAL.confirmButtonColor,
        denyButtonColor: clubConfig.secondaryColor || BASE_MODAL.denyButtonColor,
      });
      Swal.fire(options).then((result) => {
        if (result.isConfirmed) {
          if (device === 'desktop') {
            this.showQR(clubConfig.name, this.waQR);
          } else {
            open(`https://wa.me/${this.phoneNumber}?text=${this.getMessage(type, clubConfig)}`);
          }
          this.onFreeConciergeClick(`${clubConfig.name} - wa info`);
        } else if (result.isDenied) {
          if (device === 'desktop') {
            this.showQR(clubConfig.name, this.smsQR);
          } else {
            open(`sms:+${this.phoneNumber}&body=${this.getMessage(type, clubConfig)}`);
          }
          this.onFreeConciergeClick(`${clubConfig.name} - sms info`);
        }
      });
    },
    getDialogHtml(type, clubConfig) {
      if (type === 'generic') {
        return `<p>TALK TO US.</p><hr><small>The Single Contact for All Clubs & Events: Simplify your planning!</small><br>`;
      }
      return `<h2 class="alt-font" style="text-transform: uppercase;font-size: 1.9rem">${clubConfig.name}</h2><hr style="margin-top: -15px"><p style="font-size: 11px;letter-spacing: 0.9px">${this.legend}</p>`;
    },
    getMessage(type, clubConfig) {
      if (type === 'generic') {
        return this.message || 'Hey there, how can we help you? We\'ll be glad to help';
      }
      return this.message || `RSVP:%20${clubConfig.name}%0a--------%0aFULL_NAME:%20%0aDATE:%20%0aARRIVAL_TIME:%20%0aGROUP_SIZE:%20`;
    },
    showQR(club, qrImageUrl) {
      let options = Object.assign({}, BASE_MODAL, {
        html: '<p>Scan the QR and send us a message with your inquiry. We\'ll be glad to help.</p>',
        imageWidth: 270,
        imageHeight: 270,
        showDenyButton: false,
        showConfirmButton: false,
        footer: '<small>We operate from 9AM - 10PM / Answer within 15 mins</small>',
        imageUrl: qrImageUrl,
        imageAlt: 'QR for Contact',
      });
      Swal.fire(options);
    },
    onFreeConciergeClick(club) {
      window.ga('send', 'event', 'reservations', 'Clubs: Info & Reservations', club, 40);
      this.$rollbar.info('Clubs: Info & Reservations:' + club);
    },
    mailto(club) {
      return this.mailAddress ? `mailto:${this.mailAddress}` : `mailto:bookings+${club.name.toLowerCase().replaceAll('@', '').replaceAll('|', '').replaceAll(/\s/g, '-') || ''}@tulum.party`;
    },
    mailFooter(club) {
      return `<div class="text-center" style="margin-top: -20px">
          <a href="${this.mailto(club)}" class="btn btn-sm btn-link text-center">via email</a>
          <hr>
          <small>${this.footer}</small>
          <br>
          <br>
          <small>We operate from 9AM - 10PM <br>Answer within 15 mins</small>
        </div>`;
    },
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4 {
  color: black !important;
}

button {
  color: black !important;
}
</style>
